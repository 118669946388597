
.kiwi-channelbanlist {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin: 10px 0;
}

.kiwi-banlist-refresh:not(.u-link) {
    cursor: default;
}

.kiwi-banlist-ban {
    display: flex;
    width: 100%;
    box-sizing: border-box;

    > input {
        flex-grow: 1;
        margin-right: 10px;
    }
}
