
@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    src: url('../res/fonts/Roboto-Black.woff2') format('woff2'),
         url('../res/fonts/Roboto-Black.woff') format('woff')
         url('../res/fonts/Roboto-Black.ttf') format('truetype');
    font-display: auto;
}

.kiwi-avatar {
    font-size: 0;
    line-height: 0;
    user-select: none;
}

.kiwi-avatar-status {
    transition: fill 0.5s ease;
}

.kiwi-avatar-status--toggle {
    cursor: pointer;
}

.kiwi-avatar-initials {
    font-family: Roboto, Arial, sans-serif;
    font-weight: 900;
}
