
.kiwi-invitelist {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin: 10px 0;
}

.kiwi-invitelist-status {
    font-weight: 600;

    > a {
        font-weight: initial;
    }
}

.kiwi-invitelist-auto-complete {
    position: relative;
    bottom: 0;

    .kiwi-autocomplete-item {
        padding: 5px 11px;
    }
}

.kiwi-invitelist-refresh:not(.u-link) {
    cursor: default;
}

.kiwi-invitelist-invite {
    display: flex;
    width: 100%;
    box-sizing: border-box;

    > input {
        flex-grow: 1;
        margin-right: 10px;
    }
}
