
.kiwi-channelinfo {
    .kiwi-channelinfo-topic {
        margin-bottom: 10px;
    }

    .kiwi-channelinfo-password {
        margin-top: 10px;
    }
}

.kiwi-channelinfo-previoustopics {
    margin-bottom: 10px;
}

.kiwi-channelinfo-previoustopics ul {
    margin-top: 0;
    margin-left: 6px;
}
