
.kiwi-sidebar-buffersettings {
    overflow: hidden;
    height: 100%;
}

.kiwi-sidebar-settings {
    padding: 0 1em 1em 1em;

    &:first-of-type {
        margin-top: 1em;
    }

    label {
        display: block;
    }
}

.kiwi-sidebar-settings-disabled {
    .u-form input[type='checkbox'] {
        cursor: not-allowed;
    }
}

.kiwi-channelsettings-disabled {
    float: right;
    border-radius: 4px;
    font-size: 0.8em;
    padding: 0 16px;

    > i {
        margin-left: 5px;
    }
}

.kiwi-sidebar-accesstab {
    margin-right: 1em;
}

.kiwi-sidebar-accesstab--active {
    font-weight: bold;
}

.kiwi-sidebar-settings-access-table {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr minmax(0, 1fr) repeat(2, min-content);

    > div {
        border-bottom: 1px solid;
        padding-right: 4px;
    }

    &:not(.kiwi-sidebar-settings-access-restricted) {
        > div:nth-child(4n+4) {
            padding-right: initial;
        }
    }

    &.kiwi-sidebar-settings-access-restricted {
        grid-template-columns: 2fr minmax(0, 1fr) min-content;

        > div:nth-child(3n+3) {
            padding-right: initial;
        }
    }

    .kiwi-sidebar-settings-access-table-header {
        font-weight: 600;
        white-space: nowrap;
    }

    .kiwi-sidebar-settings-access-mask {
        word-break: break-all;
    }

    .kiwi-sidebar-settings-access-who {
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        position: relative;

        &:hover {
            white-space: initial;
            word-break: break-all;
        }
    }

    .kiwi-sidebar-settings-access-when {
        white-space: nowrap;
    }

    .kiwi-sidebar-settings-access-actions {
        text-align: center;
        position: relative;
        transition: all 0.3s;
        z-index: 1;

        > i {
            padding: 0 5px;
            cursor: pointer;
        }
    }
}
