
.kiwi-wrap.kiwi-error {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow: auto;
    color: #000;

    .kiwi-name {
        color: #379a79;
    }

    > img {
        max-width: 90vw;
        max-height: 60vh;
    }

    > span {
        display: inline-block;
        padding: 1em;
        margin: 0.5em 1em 0;
        font-size: 1.2em;
        text-align: left;
        background: #ffbaba;
        border: 2px solid #b51111;
    }
}
