
.kiwi-autocomplete {
    box-sizing: border-box;
    overflow-y: auto;
    position: absolute;
    bottom: 100%;
    right: 0;
    left: 0;
    z-index: 1;
}

.kiwi-autocomplete-item {
    padding: 5px 2em;
    cursor: pointer;
}

.kiwi-autocomplete-item-value {
    font-weight: 700;
}

.kiwi-autocomplete-type--command {
    .kiwi-autocomplete-item-details {
        margin-left: 8px;
    }
}

.kiwi-autocomplete-type--user {
    .kiwi-autocomplete-item-details {
        float: right;
        font-size: 0.9em;
    }
}

.kiwi-autocomplete-type--channel {
    .kiwi-autocomplete-item-details {
        float: right;
        font-size: 0.9em;
        width: 3em;
    }

    .fa-user {
        margin-right: 4px;
    }
}

@supports (grid-template-rows: subgrid) {
    .kiwi-autocomplete {
        display: grid;
        column-gap: 8px;
        grid-template-columns: minmax(7em, max-content) auto max-content;
    }

    .kiwi-autocomplete-item {
        display: grid;
        grid-column: span 3;
        grid-template-columns: subgrid;
    }

    .kiwi-autocomplete-type--command {
        .kiwi-autocomplete-item-details {
            margin-left: unset;
            grid-column: span 2;
        }
    }

    .kiwi-autocomplete-type--user,
    .kiwi-autocomplete-type--channel {
        .kiwi-autocomplete-item-value {
            grid-column: span 2;
        }

        .kiwi-autocomplete-item-details {
            float: unset;
        }
    }

    .kiwi-autocomplete-type--default {
        .kiwi-autocomplete-item-value {
            grid-column: span 3;
        }
    }
}
