

.kiwi-awaystatusindicator {
    display: inline-block;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    margin: 0 4px 0 0;
    transition: background-color 0.5s;
}

.kiwi-awaystatusindicator--self {
    cursor: pointer;
}

