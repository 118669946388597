
.kiwi-nicklist-user {
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 26px;
    max-height: 26px;
    padding: 0 10px;
    line-height: initial;
    white-space: nowrap;
    cursor: pointer;
    border-left: 4px solid transparent;
    transition: all 0.1s;

    .kiwi-nicklist--avatars & {
        height: 38px;
        max-height: 38px;
        padding: 4px 10px;
    }
}

.kiwi-nicklist-avatar {
    position: relative;
    flex-shrink: 0;
    margin-right: 10px;

    .kiwi-avatar {
        width: 30px;
        height: 30px;
    }
}

.kiwi-nicklist-awaystatus {
    flex-shrink: 0;
    margin-right: 6px;
    border: none;
}

.kiwi-nicklist-user-prefix {
    flex-shrink: 0;
}

.kiwi-nicklist-user-nick {
    display: block;
    flex: 1;
    margin-right: 10px;
    overflow: hidden;
    font-weight: 700;
    text-overflow: ellipsis;
}

.kiwi-nicklist-user-buttons {
    position: relative;
    display: flex;
    align-items: center;
}

.kiwi-nicklist-user-typing {
    width: 18px;
    height: 18px;
    visibility: hidden;
    opacity: 1;

    &--active,
    &--paused {
        visibility: visible;
    }

    > circle {
        opacity: 0.2;
        animation: 1.2s blink infinite;
        animation-play-state: paused;

        &:nth-child(2) {
            animation-delay: 0.3s;
        }

        &:nth-child(3) {
            animation-delay: 0.6s;
        }
    }

    &--active > circle {
        animation-play-state: running;
    }

    .kiwi-nicklist-user:hover & {
        opacity: 0;
        transition: opacity 0.3s;
    }

    @keyframes blink {
        33% {
            opacity: 0.9;
        }
    }
}

.kiwi-nicklist-user-message {
    position: absolute;
    right: -36px;
    width: 18px;
    height: 18px;
    opacity: 0;
    transition: right 0.3s, opacity 0.3s, fill 0.1s;

    .kiwi-nicklist-user:hover & {
        right: 0;
        opacity: 1;
    }
}
